import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  max-width: 1160px;
  width: 100%;
  padding: 32px;
  margin: 0 auto;
  padding: 70px 32px;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding: 30px;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
  > div {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 1.5em;
      li {
        padding-left: 1.28571429em;
        text-indent: -1.28571429em;
        margin-bottom: 1em;
      }
    }
    h3 {
      margin-top: 2em;
      color: ${props => props.theme.colors.secondary};
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      strong {
        color: ${props => props.theme.colors.text.dark};
      }
    }
  }
`;

export default Wrapper;
