import React from 'react';

// Components
import { Wrapper } from './files';

const JustText = ({ contentSection }) => (
  <Wrapper>
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: contentSection.text.childMarkdownRemark.html
        }}
      />
    </div>
  </Wrapper>
);

export default JustText;
